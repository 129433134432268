import _filter from 'lodash/filter'
import icon from '../src/configs/image.config'

export const socialSite = {
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
  MEDIUM: 'medium',
  YOUTUBE: 'youtube',
  REDDIT: 'reddit',
  GITHUB: 'github',
  EMAIL: 'email',
  KOFI: 'kofi',
  PATREON: 'patreon',
  SUBSTACK: 'substack',
  RSS: 'rss',
}

const socialProfiles = {
  [socialSite.LINKEDIN]: {
    id: socialSite.LINKEDIN,
    alt: "Ashmeet's  linkedin profile",
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/in/ashmeetsehgal',
    description: 'Tech updates/posts',
    sequence: 1,
    icon: icon[socialSite.LINKEDIN],
  },
  [socialSite.TWITTER]: {
    id: socialSite.TWITTER,
    alt: "Ashmeet's X profile",
    title: 'X (Twitter)',
    link: 'https://twitter.com/ashmeetsehgal',
    description: 'My day to day writings',
    sequence: 2,
    icon: icon[socialSite.TWITTER],
  },
  [socialSite.INSTAGRAM]: {
    id: socialSite.INSTAGRAM,
    alt: "Ashmeet's instagram profile",
    title: 'Instagram',
    link: 'https://www.instagram.com/ashmeetsehgaldotcom',
    description: 'Not getting much time for this',
    sequence: 3,
    icon: icon[socialSite.INSTAGRAM],
  },
  [socialSite.FACEBOOK]: {
    id: socialSite.FACEBOOK,
    alt: "Ashmeet's facebook profile",
    title: 'Facebook',
    link: 'https://www.facebook.com/ashmeet.sehgal',
    description: "I don't know why I'm on fb",
    sequence: 4,
    shouldHide: false,
    icon: icon[socialSite.FACEBOOK],
  },
  // [socialSite.MEDIUM]: {
  //   id: 'medium',
  //   alt: 'medium',
  //   title: 'Medium',
  //   link: 'https://medium.com/@ashmeetsehgal',
  //   description: 'Detailed posts',
  //   sequence: 4,
  // },
  [socialSite.YOUTUBE]: {
    id: 'youtube',
    alt: 'youtube',
    title: 'Youtube',
    link: 'https://www.youtube.com/@ashmeetsehgal',
    description: 'Some experimental videos',
    sequence: 5,
    icon: icon[socialSite.YOUTUBE],
  },
  [socialSite.REDDIT]: {
    id: socialSite.REDDIT,
    alt: "Ashmeet's reddit profile",
    title: 'Reddit',
    link: 'https://www.reddit.com/user/ashmeetsehgal',
    description: 'Lit things happen here',
    sequence: 6,
    shouldHide: true,
    icon: icon[socialSite.REDDIT],
  },
  [socialSite.GITHUB]: {
    id: socialSite.GITHUB,
    alt: "Ashmeet's github profile",
    title: 'Github',
    link: 'https://github.com/ashmeetsehgal',
    description: 'My code prototypes go here',
    sequence: 7,
    icon: icon[socialSite.GITHUB],
  },
  [socialSite.EMAIL]: {
    id: socialSite.EMAIL,
    alt: "Ashmeet's Email",
    title: 'Email',
    link: 'mailto:ashmeet@ashmeetsehgal.com?&subject=ashmeetsehgal.com||your-subject-here&body=Please-start-writing-here',
    description: '1 on 1 conversation',
    sequence: 8,
    icon: icon[socialSite.EMAIL],
  },
  shareLink: {
    id: 'shareLink',
    alt: 'copy link',
    title: 'Share Link',
    sequence: 9,
    shouldHide: true,
    icon: icon.shareLink,
  },
}

export default socialProfiles

export const socialProfilesToShow = _filter(
  socialProfiles,
  (socailProfile) => !socailProfile.shouldHide || false,
)

export const supportProfiles = {
  [socialSite.KOFI]: {
    id: socialSite.KOFI,
    alt: "ashmeet's ko-fi profile",
    title: 'Ko-fi profile',
    link: 'https://ko-fi.com/ashmeet',
    icon: icon[socialSite.KOFI],
  },
  [socialSite.PATREON]: {
    id: socialSite.PATREON,
    alt: "ashmeet's patreon profile",
    title: 'patreon profile',
    link: 'https://www.patreon.com/ashmeetsehgal',
    icon: icon[socialSite.PATREON],
  },
  [socialSite.SUBSTACK]: {
    id: socialSite.SUBSTACK,
    alt: "ashmeet's substack profile",
    title: 'substack profile',
    link: 'https://ashmeet.substack.com',
    icon: icon[socialSite.SUBSTACK],
  },
  [socialSite.RSS]: {
    id: socialSite.RSS,
    alt: 'rss feed',
    title: 'RSS feed',
    link: '/rss.xml',
    icon: icon[socialSite.RSS],
  },
}
