import React from 'react'
import config from '../../data/SiteConfig'
import { ButtonLink } from './Button'
// import useOnScreen from '../hooks/useOnScreen'

export function SiteLink() {
  return (
    <ButtonLink
      simpleLink
      href="https://ashmeetsehgal.com"
      rel="external noopener noreferrer"
      target="_blank"
      title="ashmeetsehgal.com HomePage"
    >
      https://ashmeetsehgal.com
    </ButtonLink>
  )
}

export function MailLink() {
  return (
    <ButtonLink
      simpleLink
      href="mailto:sglashmeet@gmail.com?&subject=ashmeetsehgal.com||your-subject-here&body=Please-start-writing-here"
      title="gmail"
      target="_blank"
      rel="noopener noreferrer"
    >
      sglashmeet@gmail.com
    </ButtonLink>
  )
}

export function ContactLink({ name = 'https://ashmeetsehgal.com/contact' }) {
  return (
    <ButtonLink
      simpleLink
      href="https://ashmeetsehgal.com/contact"
      rel="external noopener noreferrer"
      target="_blank"
      title="ashmeetsehgal.com Contact Page"
    >
      {name}
    </ButtonLink>
  )
}

export function NewsLetter() {
  return (
    <ButtonLink
      rel="noopener noreferrer"
      target="_blank"
      href={config.newsletter}
      simpleLink={false}
    >
      Subscribe
    </ButtonLink>
  )

  // return (
  //   <div>
  //     <iframe
  //       title="newsletter"
  //       src={config.newsletterEmbed}
  //       loading="lazy"
  //       width="100%"
  //       height="100%"
  //       className="newsletter-iframe"
  //       frameBorder="0"
  //       scrolling="no"
  //     />
  //   </div>
  // )
}
